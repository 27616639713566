import "./App.css";
import About from "./component/About/About";

import Navbar from "./component/Home/Navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./component/Home/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate } from "react-router-dom";

import Program1 from "./component/Program/program1";
import Contact1 from "./component/contact/contact1";
import Curriculum from "./component/Cirrculum/Curriculum";
import Admission from "./component/Admission/Admission";
import useLoginStore from "./store/useLoginStore";
import Login  from "./component/Login/login";
function App() {
  const { isLoggedIn } = useLoginStore();
  return (
   
      <Router>
        <ToastContainer className="!z-[999999]" />
        <div className="flex w-full ">
          {isLoggedIn && <Navbar />}
       
          <div className="flex-grow">
            <Routes>
            {!isLoggedIn ? (
              <>
                {/* Redirect all routes to Login if not logged in */}
                <Route path="*" element={<Login />} />
              </>
               ) : (
                <>
               <Route path="/Home" element={<Home />} />
              <Route path="/About" element={<About />} />
              <Route path="/Admission" element={<Admission />} />
              <Route path="/Program" element={<Program1 />} />
              <Route path="/Curriculum" element={<Curriculum />} />
              <Route path="/Contact1" element={<Contact1 />} />
<Route path="/" element={<Navigate replace to="/Home" />} />
               <Route path="*" element={<Navigate replace to="/Home" />} />
              </>
               )}
            </Routes>
          </div>
        </div>
      </Router>
  
  );
}

export default App;



