import React, { useEffect, useState } from "react";
import SaveButton from "../Buttons/saveButton";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import PublishButton from "../publish/publishButton";
import { SaveChangesPopup } from "./savePopup";
import { toast } from "react-toastify";

const Heading = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [description, setDescription] = useState("");
  const [heading, setHeading] = useState("");
  const [link, setLink] = useState("");
  const [slideId, setSlideId] = useState(1);
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const handleImageChange = (e) => {
    const file = e.target.files[0] || e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleImageChange({ target: { files: [file] } });
  };

  async function saveHomeData() {
    try {
      const token = sessionStorage.getItem("token");
      const payload = {
        id: 1,
        Link: link,
        Heading: heading,
        Description: description,
        Photos: selectedImage,
        Published: toggleSwitch,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${API_BASE_URL}/createData`,
        payload,
        config
      );
      if (response.data.status) {
        toast.success("Data created successfully");
      }
    } catch (e) {
      console.log("Error:", e);
    }
  }

  function inputHeadingChange(e) {
    setHeading(e.target.value);
  }
  function inputLinkChange(e) {
    setLink(e.target.value);
  }
  function inputDescriptionChange(e) {
    if (e.target.value?.split(" ").filter(Boolean).length <= 100) {
      setDescription(e.target.value);
    }
  }
  const getHomeData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getHomeData`);
      setSlideId(response.data.data?.id || 1);
      setDescription(response.data.data?.Description);
      setHeading(response.data.data?.Heading);
      setLink(response.data.data?.Link);
      setSelectedImage(response.data.data?.Photos);
      setToggleSwitch(response?.data?.data?.Published);
    } catch (e) {
      console.log("err", e);
    }
  };
  useEffect(() => {
    getHomeData();
  }, []);
  function onCancel() {

    setSelectedImage(null);
    setDescription("");
    setHeading("");
    setLink("");
    setToggleSwitch(false);
  }

  async function handleSwitch(e, activeSlideId) {
    // e.stopPropagation();
    try {
      const token = sessionStorage.getItem("token");
      const config = {
        method: "PUT",
        mode: "cors",
        url: `${API_BASE_URL}/updateData/${1}`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        data: { Published: toggleSwitch },
      };
      await axios(config);
    } catch (e) {
      console.log("Error:", e);
    }
  }
  const [togglePop, setTogglePop] = useState(false);
  const [activeSlideId, setActiveSlideId] = useState(null);
  const openPopUp = (slideId) => {
    console.log("trigger open for slide", slideId);
    setActiveSlideId(slideId);
    setTogglePop(true);
    setToggleSwitch(!toggleSwitch);
  };
  const closePopUp = () => {
    setActiveSlideId(null);
    setTogglePop(false);
  };
  const onSaveChanges = async () => {
    // Close the popup first
    closePopUp();
    // Proceed with saving changes
    if (activeSlideId !== null) {
      await handleSwitch(activeSlideId);
    }
  };
  return (
    <>
      <div className="2xl:ml-[90px] ml-[20px] lg:ml-[40px]  2xl:w-[970px] h-auto lg:w-[700px] rounded-3xl  bg-[#FFFFFF]">
        <div className="flex flex-row">
          <SaveChangesPopup
            open={togglePop}
            onSave={onSaveChanges}
            onClose={closePopUp}
          />
          <div className="flex flex-col font-['Roboto']">
            <label className="ml-[30px] mt-[27px] 2xl:[14px]  font-semibold text-[#1A233899]">
              Heading
            </label>
            <input
              type="text"
              className="mt-[4px] text-[12px] ml-[30px]  border border-1 border-[#0000003B] px-2 py-2 2xl:w-[460px] 2xl:h-[56px] lg:w-[350px] lg:h-[40px]  rounded"
              value={heading}
              placeholder="Type heading here ....."
              name="Heading"
              onChange={(e) => inputHeadingChange(e)}
            />
            <label className="ml-[30px] mt-[27px] 2xl:text-[14px] font-semibold text-[#1A233899]">
              Description
            </label>
            <textarea
              className="ml-[30px] text-[12px] border border-1 border-[#0000003B] px-2 py-2 rounded  lg:text-[12px] font-semibold text-[#1A233899] 2xl:w-[460px] lg:w-[360px] 2xl:h-[180px] lg:h-[130px]"
              value={description}
              placeholder="Type Description ...."
              name="chapterDescription"
              onChange={(e) => inputDescriptionChange(e)}
            ></textarea>
            <p className="ml-[30px] text-[#1A233899] font-sm">
              {description?.split(" ").filter(Boolean).length}
              /100 Words Remaining
            </p>
            <label className="ml-[30px] font-semibold text-[#1A233899]  mt-[27px] ">
              Button link
            </label>
            <input
              type="text"
              className="ml-[30px] mb-[2rem] text-xs   border border-1 border-[#0000003B] px-2 py-2 2xl:w-[460px] 2xl:h-[56px] lg:w-[350px] lg:h-[40px]  rounded-lg"
              value={link}
              placeholder="Type Link here ....."
              name="Link"
              onChange={(e) => inputLinkChange(e)}
            />
          </div>
          <div className="flex flex-col font-['Roboto']">
            <h1 className="2xl:ml-[32px] lg:ml-[32px] pt-[26px] font-bold text-[14px] text-[#1A233899]">
              Photo
            </h1>
            <div
              className="2xl:ml-[32px] lg:ml-[33px] flex flex-col items-center justify-center 2xl:w-[378px] lg:w-[250px] 2xl:h-[152px] lg:h-[150px] rounded bg-[#C2C2C28F]"
              onDoubleClick={handleClick}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={handleImageChange}
                accept="image/*"
              />
              {selectedImage ? (
                <img
                  src={selectedImage}
                  alt="uploaded"
                  className="object-cover w-full h-full rounded"
                />
              ) : (
                <img src="/Vector.png" alt="upload-icon" className="" />
              )}
              {!selectedImage && (
                <>
                  <p className="text-[12px] flex self-end lg:mt-[4px] text-[#999999]">
                    "Drag & Drop" or <br /> "Double click to upload image"
                  </p>
                </>
              )}
            </div>
            <p className="flex self-end lg:mt-[4px]  text-xs text-gray-400">
              SVG, PNG, JPG or GIF (max. 5MB)
            </p>
            <div className=" 2xl:mt-[90px] flex self-end ">
              <SaveButton onSave={saveHomeData} onCancel={onCancel} />
            </div>
          </div>
          <div className="mt-3">
            <PublishButton
              isPublished={toggleSwitch}
              openPopUp={() => openPopUp(slideId)}
              slideId={slideId}
              className="flex justify-self-end"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Heading;
