import React, { useEffect, useState } from "react";

import PublishButton from "../publish/publishButton";
import { SaveChangesPopup } from "../Home/savePopup";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";
import CurriculumUpload from "./curriculumUpload";

const Curriculum = () => {
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const [togglePop, setTogglePop] = useState(false);
  const [activeSlideId, setActiveSlideId] = useState(1);
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");

  const openPopUp = (slideId) => {
    setActiveSlideId(slideId);
    setTogglePop(true);
    setToggleSwitch(!toggleSwitch);
  };
  const closePopUp = () => {
    setActiveSlideId(1);
    setTogglePop(false);
  };

  async function handleSwitch(e, activeSlideId) {
    // e.stopPropagation();
    try {
      const token = sessionStorage.getItem("token");
      const config = {
        method: "PUT",
        mode: "cors",
        url: `${API_BASE_URL}/updatacurriculumeData/${1}`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        data: { Published: toggleSwitch },
      };
      const response = await axios(config);
      console.log(response?.data?.status);
      if (response?.data?.status) {
        toast.success("Published Successfully");
      }
    } catch (e) {
      console.log("Error:", e);
    }
  }
  const onSaveChanges = async () => {
    // Close the popup first
    closePopUp();
    // Proceed with saving changes
    if (activeSlideId !== null) {
      await handleSwitch(activeSlideId);
    }
  };
  const getAboutData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getcurriculumData`);
      setImage(response.data.data?.Photo);
      setActiveSlideId(response.data.data?.id);
      setToggleSwitch(response.data.data?.Published);
      setDescription(response?.data?.data?.Description);
      console.log(response);
    } catch (e) {
      console.log("err", e);
    }
  };
  useEffect(() => {
    getAboutData();
  }, []);

  return (
    <>
      <div className="bg-[#F1F5F9] bg-cover min-h-screen">
        <div className="flex flex-col font-['Roboto']">
          <h1
            className="lg:ml-[3rem]  font-['Roboto'] font-semibold lg:text-lg 2xl:text-4xl text-[#1A2338]"
            style={{ lineHeight: "78px" }}
          >
            Curriculum
          </h1>
          <p className="font-bold lg:text-[24px] 2xl:text-xl text-sm lg:ml-[3rem]  text-[#1A2338B2] ">
            Hero image
          </p>
        </div>
        <div className="ml-[20px] lg:ml-[40px] rounded-3xl w-[592px] mt-4  bg-[#FFFFFF]">
          <div className="flex flex-row items-center">
            <div className="flex flex-col">
              <div className="flex">
                <h1 className="ml-[3rem] pt-[20px] font-bold text-sm text-left text-[#1A233899]">
                  Image
                </h1>
                <div className="lg:ml-[26rem] mt-[2rem]">
                  <PublishButton
                    isPublished={toggleSwitch}
                    openPopUp={() => openPopUp(1)}
                    slideId={1}
                  />
                </div>
              </div>
              <SaveChangesPopup
                open={togglePop}
                onSave={onSaveChanges}
                onClose={closePopUp}
              />
              <CurriculumUpload
                selectedImage={image}
                setImage={setImage}
                setDescription={setDescription}
                description={description}
                setToggleSwitch={setToggleSwitch}
                setActiveSlideId={setActiveSlideId}
                toggleSwitch={toggleSwitch}
                slideId={1}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Curriculum;
