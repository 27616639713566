import React, { useState } from "react";
import { SaveChangesPopup } from "../../component/Home/savePopup";
import Programs from "../Program/Programs";
import Banner from "./Banner";

const Program1 = () => {
  const [showPopUp, setShowPopUp] = useState(false);
  async function handleSave() {}
  function handleClose() {
    setShowPopUp(false);
  }

  return (
    <div className="bg-[#F1F5F9] bg-cover min-h-screen">
      <Banner />
      <Programs />
      <SaveChangesPopup
        onSave={handleSave}
        open={showPopUp}
        onClose={handleClose}
      />
    </div>
  );
};

export default Program1;
