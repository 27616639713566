import React from "react";
import SaveButton from "../Buttons/saveButton";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import { toast } from "react-toastify";

const CurriculumUpload = ({
  selectedImage,
  setImage,
  setToggleSwitch,
  toggleSwitch,
  description,
  setDescription,
  slideId,
  setActiveSlideId,
}) => {
  console.log(description);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleImageChange({ target: { files: [file] } });
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCancel = () => {
    setImage(null);
    setDescription("");
  };
  async function saveAboutData() {
    try {
      const token = sessionStorage.getItem("token");
      const payload = {
        id: slideId,
        Photo: selectedImage,
        Description: description,
        Published: toggleSwitch,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${API_BASE_URL}/createcurriculumData`,
        payload,
        config
      );
      console.log(response?.data?.status);
      if (response?.data?.status) {
        toast.success("Updated Successfully");
      }
      setImage(response.data.data?.Photo);
      setToggleSwitch(response?.data?.data?.Published);
      setDescription(response?.data?.data?.Description);
      setActiveSlideId(response?.data?.data?.id);
    } catch (e) {
      console.log("Error:", e);
    }
  }
  const handleSave = () => {
    saveAboutData();
  };

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div
        className="2xl:ml-[32px] lg:ml-[33px] flex flex-col items-center justify-center 2xl:w-[400px] lg:w-[280px] 2xl:h-[13rem] lg:h-[250px] rounded bg-[#C2C2C28F]"
        onDoubleClick={handleClick}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={handleImageChange}
          accept="image/*"
        />
        {selectedImage ? (
          <img
            src={selectedImage}
            alt="uploaded"
            className="object-cover w-full h-full rounded"
          />
        ) : (
          <img src="/Vector.png" alt="upload-icon" className="" />
        )}
        {!selectedImage && (
          <>
            <p className="text-sm text-gray-500 mt-[11px]">
              "Drag & Drop" or <br /> "Double click to upload image"
            </p>
          </>
        )}
      </div>
      <p className="flex self-end lg:mt-[1rem]  text-xs text-gray-400 mr-[10rem]">
        SVG, PNG, JPG or GIF (max. 5MB)
      </p>
      <div className="flex flex-col">
        <label className=" 2xl:text-[14px] font-semibold text-[#1A233899] ml-[3rem] mt-6">
          Description
        </label>
        <textarea
          className="text-[12px] border border-1 border-[#0000003B] px-2 py-2 rounded  lg:text-[12px] font-semibold text-[#1A233899]  lg:w-[390px] lg:h-[107px] ml-[3rem]"
          placeholder="Type Description ...."
          name="description"
          value={description}
          onChange={(e) => handleDescriptionChange(e)}
        ></textarea>
        <p className="text-[#1A233899] font-sm ml-[3rem]">
          {description?.split(" ").filter(Boolean).length}/500 Words Remaining
        </p>
      </div>
      <div className=" flex self-end mr-[2rem] ">
        <SaveButton onSave={handleSave} onCancel={handleCancel} />
      </div>
    </>
  );
};

export default CurriculumUpload;
