import React from "react";
import SaveButton from "../Buttons/saveButton";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import { toast } from "react-toastify";

const ImageUploadContact = ({
  selectedImage,
  setImage,
  address,
  email,
  phone,
  setToggleSwitch,
  toggleSwitch,
  slideId,
}) => {
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleImageChange({ target: { files: [file] } });
  };
  const handleCancel = () => {
    setImage(null);
  };
  async function saveAboutData() {
    try {
      const payload = {
        id: slideId,
        Address: address,
        Photo: selectedImage,
        Email: email,
        Phone: phone,
        Published: toggleSwitch,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        `${API_BASE_URL}/contactpageData`,
        payload,
        config
      );
      console.log(response?.data?.status);
      if (response?.data?.status) {
        toast.success("Updated Successfully");
      }
      setImage(response.data.data?.Photo);
      setToggleSwitch(response?.data?.data?.Published);
    } catch (e) {
      console.log("Error:", e);
    }
  }
  const handleSave = () => {
    saveAboutData();
  };

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div
        className="2xl:ml-[32px] lg:ml-[33px]  flex flex-col items-center justify-center w-[378px] h-[210px] pt-[3rem] pb-[1rem]  rounded bg-[#C2C2C28F]"
        onDoubleClick={handleClick}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={handleImageChange}
          accept="image/*"
        />
        {selectedImage ? (
          <img
            src={selectedImage}
            alt="uploaded"
            className="object-cover w-full h-full rounded"
          />
        ) : (
          <img src="/Vector.png" alt="upload-icon" className="" />
        )}
        {!selectedImage && (
          <>
            <p className="text-sm text-gray-500 mt-[11px]">
              "Drag & Drop" or <br /> "Double click to upload image"
            </p>
          </>
        )}
      </div>

      <p className="flex self-end text-xs text-gray-400 mr-[3rem]">
        SVG, PNG, JPG or GIF (max. 5MB)
      </p>

      <div className=" flex self-end mr-[4rem] ">
        <SaveButton onSave={handleSave} onCancel={handleCancel} />
      </div>
    </>
  );
};

export default ImageUploadContact;
